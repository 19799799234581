<template>
  <h2 class="title">{{ $t('tariffs_all_in') }}</h2>
  <div class="tariff-info">
    <div class="tariff-info__card tariff-info__item1">
      <span class="tariff-info__card__title" :style="{color: color}">{{ $t('tariffs_info1_title') }}</span>
      <ul>
        <li :style="{color: color}"><span>{{ $t('tariffs_info1_item1') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info1_item2') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info1_item3') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info1_item4') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info1_item5') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info1_item6') }}</span></li>
      </ul>
      <img class="tariff-info__img_1" :class="{'tariff-info__img_1--rtl': isArabic}" :src="`${staticUrl}/images/tariff_item2.png`" alt="">
    </div>

    <div class="tariff-info__card tariff-info__item2">
      <span class="tariff-info__card__title" :style="{color: color}">{{ $t('tariffs_info2_title') }}</span>
      <ul>
        <li :style="{color: color}"><span>{{ $t('tariffs_info2_item1') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info2_item2') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info2_item3') }}</span></li>
      </ul>
    </div>

    <div class="tariff-info__card tariff-info__item3">
      <span class="tariff-info__card__title" :style="{color: color}">{{ $t('tariffs_info3_title') }}</span>
      <ul>
        <li :style="{color: color}"><span>{{ $t('tariffs_info3_item1') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info3_item2') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info3_item3') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info3_item4') }}</span></li>
      </ul>
    </div>

    <div class="tariff-info__card tariff-info__item4">
      <span class="tariff-info__card__title" :style="{color: color}">{{ $t('tariffs_info4_title') }}</span>
      <ul>
        <li :style="{color: color}"><span>{{ $t('tariffs_info4_item1') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info4_item2') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info4_item3') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info4_item4') }}</span></li>
        <li :style="{color: color}"><span>{{ $t('tariffs_info4_item5') }}</span></li>
      </ul>
      <img class="tariff-info__img_2" :class="{'tariff-info__img_2--rtl': isArabic}" :src="`${staticUrl}/images/tariff_item1.png`" alt="">
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports';
import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';

const props = withDefaults(
  defineProps<{
    color?: string;
  }>(),
  {
    color: '#0081FF',
  },
);

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;

// Проверка на наличие 'ar' в URL
const route = useRoute();
const localeFromPath = getLocaleFromPath(route.fullPath);
const isArabic = computed(() => localeFromPath === 'ar');
</script>

<style scoped lang="scss">
li {
  margin-top: 14px;
}

li span {
  color: #64686E;
}

ul {
  padding-inline-start: 20px;
}

.title {
  //styleName: Headline/H1;
  color: #3d424a;
  margin-top: 120px;
  margin-bottom: 0;
  font-family: Segoe UI;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  letter-spacing: 0.03em;
  text-align: center;
}

.tariff-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-top: 64px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
}

.tariff-info__card {
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 32px;
  background: #F6F8FD;
  border-radius: 8px;
}

.tariff-info__card__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.92px;
  letter-spacing: 0.03em;
  text-align: left;
}

.tariff-info__item1 {
  @media (min-width: 1024px) {
    grid-column: 1/3;
  }
}

.tariff-info__item2 {
  @media (min-width: 1024px) {
    grid-column: 3/4;
  }
}

.tariff-info__item3 {
  @media (min-width: 1024px) {
    grid-column: 1/2;
  }
}

.tariff-info__item4 {
  @media (min-width: 1024px) {
    grid-column: 2/4;
  }
}

.tariff-info__img_1 {
  position: absolute;
  right: 10px;
  top: 40px;
  height: 280px;

  @media (max-width: 769px) {
    top: initial;
    position: relative;
    width: 290px;
    margin-top: 30px;
  }
}

.tariff-info__img_1--rtl {
  left: 10px;
  right: initial;
}

.tariff-info__img_2 {
  position: absolute;
  right: 0;
  top: 60px;
  width: 230px;

  @media (max-width: 769px) {
    top: initial;
    position: relative;
    width: 290px;
    margin-top: 20px;
  }
}

.tariff-info__img_2--rtl {
  left: 0;
  right: initial;
}

</style>
